<template>
    <div class="res-rightarea">
        <p class="recommend-course">推荐课程</p>
        <div class="res-ads">
            <!--<router-link :to="{path: '/qualityCourse/PublicClassDetail', query: {id: item.course_id}}" v-for="item in resData">-->
            <div v-for="item in resData" @click="viewPublicClassDetail(item)">
                <img :src="item.course_cover" :alt="item.course_name" :title="item.course_name">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RecommendCourse",
        data() {
            return {
                resData: []
            }
        },
        created() {
            this.getCourseList();
        },
        methods: {
            getCourseList() {
                let params = {
                    page: 1,
                    limit: 3,
                    type: 2
                }

                this.$shttp.axiosGetBy(this.$api.courses, params, (res) => {
                    if (res.code === 200) {
                        this.resData = res.list;
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            viewPublicClassDetail(item) {
                if (this.$route.path === '/qualityCourse/PublicClassDetail') {
                    this.$router.push({
                        path: '/qualityCourse/PublicClassDetail',
                        query: {
                            id: item.course_id
                        }
                    })
                } else if (this.$route.path === '/qualityCourse/PublicClass') {
                    this.$router.push({
                        path: '/qualityCourse/PublicClassDetail',
                        query: {
                            id: item.course_id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/qualityCourse/home/PublicClassDetail',
                        query: {
                            id: item.course_id
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .res-rightarea {
        width: 240px;
        margin-top: -42px;
        padding: 0 15px;
        .recommend-course {
            color: #595959;
            font-size: 14px;
            margin: 18px 0 18px 2px;
        }
        .res-ads {
            margin-left: 2px;
            div {
                overflow: hidden;
                width: 240px;
                height: 160px;
                border-radius: 5px;
                margin: 0 auto 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
</style>