<template>
    <el-scrollbar class="container bodywrap-content" style="height: 100%;" :native="false">
<!--    <div class="container bodywrap-content">-->
        <h1 class="course-manage-title ml0">公开课</h1>
        <div class="res-container row">
            <div class="res-leftarea">
                <div class="sort-btns row">
                    <div class="sort-btn">
                        <a href="javascript:void(0)" :class="{active : isActive === 0}" @click="tabTo(0)">最热</a>
                        <span class="sort-divider">|</span>
                        <a href="javascript:void(0)" :class="{active : isActive === 1}" @click="tabTo(1)">最新</a>
                    </div>
                    <div class="input-group search-input row">
                        <el-input v-model="input" placeholder="请输入搜索内容"></el-input>
                        <i class="el-icon-search"></i>
                        <el-button type="primary" @click="search">搜索</el-button>
                    </div>
                </div>
                <div class="res-list">
<!--                    <div class="tab-content">-->
                        <!--<router-link :to="{path: '/qualityCourse/PublicClassDetail', query: {id: item.course_id}}" class="pub-course-item" v-for="item in resData">-->
                            <!--<div class="pub-cover">-->
                                <!--<img :src="item.course_cover" :alt="item.course_name">-->
                            <!--</div>-->
                            <!--<div class="pub-name text-ellipsis">{{item.course_name}}</div>-->
                            <!--<div class="pub-create-time">{{item.create_time}}</div>-->
                        <!--</router-link>-->
                        <div class="pub-course-item" v-for="item in resData" @click="viewPublicClassDetail(item)">
                            <div class="pub-cover">
                                <img :src="item.course_cover" :alt="item.course_name">
                            </div>
                            <div class="pub-name text-ellipsis">{{item.course_name}}</div>
                            <div class="pub-create-time">{{item.create_time}}</div>
                        </div>
<!--                    </div>-->
                </div>
                <el-pagination class="pages-center" background style="margin: 10px 0;"
                               :current-page.sync="pubicClassPages.currentPageNum"
                               :page-size="pubicClassPages.eachPageNum"
                               :total="pubicClassPages.total"
                               layout="prev, pager, next, jumper"
                               @current-change="publicCurrentChange">
                </el-pagination>
            </div>
            <RecommendCourse></RecommendCourse>
        </div>
<!--    </div>-->
    </el-scrollbar>
</template>


<script>
    import RecommendCourse from 'components/qualityCourse/RecommendCourse';
    export default {
        name: "PublicClass",
        components: {
            RecommendCourse
        },
        created() {
            this.getCourseList();
        },
        data() {
            return {
                input: '',
                resData: [],
                isActive: 0,
                pubicClassPages: {
                    currentPageNum: 1,
                    eachPageNum: 15,
                    total: 0,
                },
            }
        },
        methods: {
            getCourseList(sort=0) {
                let params = {
                    page: this.pubicClassPages.currentPageNum,
                    limit: this.pubicClassPages.eachPageNum,
                    type: 2
                }
                if (this.input) {
                    params.course_name = this.input;
                }
                if (sort) {
                    params.sort = 1;
                }
                this.$shttp.axiosGetBy(this.$api.courses, params, (res) => {
                    if (res.code === 200) {
                        this.pubicClassPages.total = res.total
                        this.resData = res.list;
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.$router.push('/user/login');
                            }
                        });
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            publicCurrentChange(val) {
                this.pubicClassPages.currentPageNum = val;
                this.getCourseList();
            },
            search() {
                this.getCourseList();
            },
            tabTo(type) {
                this.getCourseList(type);
                this.isActive = type;
            },
            viewPublicClassDetail(item) {
                if (this.$route.path === '/qualityCourse/PublicClass') {
                    this.$router.push({
                        path: '/qualityCourse/PublicClassDetail',
                        query: {
                            id: item.course_id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/qualityCourse/home/PublicClassDetail',
                        query: {
                            id: item.course_id
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .quality-course-container {
        .bodywrap-content {
            padding: 0!important;
            /*min-height: 802px;*/
            box-sizing: border-box;
            margin: 0 auto;
            /*height: calc(100% - 84px) !important;*/
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                padding: 0 40px;
            }
        }
        .container {
            width: 100%;
            padding-right: 40px;
            padding-left: 40px;
            margin-right: auto;
            margin-left: auto;
            background: #fff;
            .course-manage-title {
                /*margin-top: 0;*/
                color: #595959;
                font-size: 18px;
                margin: 20px 0 0;
                width: 1000px;
                font-weight: bold;
                /*margin-left: 0;*/
            }
            .row {
                display: flex;
                flex-wrap: wrap;
                margin-left: -15px;
            }
            .res-container {
                display: flex;
                justify-content: start;
                margin-left: 0;
                margin-top: -15px;
                .res-leftarea {
                    flex: 1;
                    margin: 0;
                    padding: 8px 0;
                    .sort-btns {
                        margin: 0;
                        display: flex;
                        justify-content: space-between;
                        .sort-btn {
                            font-size: 14px;
                            margin-top: 18px;
                            height: 40px;
                            a {
                                color: #595959;
                                cursor: pointer;
                                vertical-align: bottom;
                                &:first-child {
                                    margin-right: 5px;
                                }
                                &:last-child {
                                    margin-left: 5px;
                                }
                                &.active {
                                    color: #4A90E2;
                                }
                            }
                            span {
                                vertical-align: bottom;
                            }
                        }
                        .input-group {
                            width: 400px;
                            margin-top: 8px;
                            display: flex;
                            justify-content: start;
                            position: relative;
                            .el-input {
                                width: 220px;
                                height: 40px;
                                margin-left: -30px;
                                box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
                                border: 1px solid #f5f5f5;
                                padding-left: 36px;
                                border-radius: 5px 0 0 5px;
                                ::v-deep .el-input__inner {
                                    border: none;
                                    padding: 0;
                                }
                            }
                            i {
                                position: absolute;
                                color: #d6cece;
                                left: -16px;
                                top: 14px;
                                z-index: 20;
                            }
                            .el-button {
                                font-size: 12px;
                                color: #FFF;
                                background-color: #4A90E2;
                                border-color: #4A90E2;
                                width: 60px;
                                height: 40px;
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                    .res-list {
                        min-height: 645px;
                        margin-left: -5px;
                        .pub-course-item {
                            width: 240px;
                            display: inline-block;
                            height: 200px;
                            margin: 0 20px 15px 5px;
                            .pub-cover {
                                overflow: hidden;
                                width: 100%;
                                height: 144px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .pub-name {
                                color: #333;
                                margin: 15px 0 5px 0;
                            }
                            .text-ellipsis {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .pub-create-time {
                                color: #888;
                            }
                        }
                    }
                }
                .col {
                    flex-basis: 0;
                    flex-grow: 1;
                    max-width: 100%;
                }
                .res-rightarea {
                    width: 240px;
                    margin-top: -26px;
                    padding: 0 15px;
                }
            }
        }
    }
</style>